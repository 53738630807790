import * as React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { Container, colors, media } from 'cc-ui-components'
import { injectIntl, InjectedIntlProps } from 'react-intl'

// @ts-ignore
import SkillsListEN from '../data/skills-en'
// @ts-ignore
import SkillsListDE from '../data/skills-de'

const SkillsMap = {
  en: SkillsListEN,
  de: SkillsListDE,
}

const Link = styled(GatsbyLink)`
  color: ${colors.lightText};
`

const Columns = styled.div`
  column-count: 4;
  ${media.lg`
    column-count: 4;
  `};
  ${media.tablet`
    column-count: 2;
  `};
  ${media.sm`
    column-count: 1;
  `};
  a {
    display: block;
  }
`

interface SkillProps {
  name: string
  url: string
}

const Skill: React.FC<SkillProps> = ({ name, url }) => (
  <Link to={url}>{name}</Link>
)

const SkillsList: React.FC<InjectedIntlProps> = ({ intl }) => {
  const skills = SkillsMap[intl.locale]
  return (
    <Container>
      <Columns>
        {skills.map((skill: SkillProps, idx: number) => (
          <Skill key={idx} {...skill} />
        ))}
      </Columns>
    </Container>
  )
}

export default injectIntl(SkillsList)
