import * as React from 'react'

import { Links as LinksDefault, LinkProps } from 'cc-ui-components'
import { mapCtaElement } from '../DynamicAssembly'

interface Props {
  elements: LinkProps[]
  title?: string
}

export const Links: React.SFC<Props> = ({ elements, ...props }) => (
  <LinksDefault {...props} elements={elements.map(mapCtaElement)} />
)
