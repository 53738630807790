import * as React from 'react'
import styled, { ThemeProps, withTheme } from 'styled-components'
import { Field, Form as FormikForm, FormikProps, withFormik } from 'formik'
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl'
import * as Yup from 'yup'
import { PageTheme } from '../../styles/config'

import { Input, Heading, Button, media } from 'cc-ui-components'
import { storeLead, contactToSource } from '../../lib/codecontrol'

interface FormValues {
  email: string
  name: string
  phone: string
  about: string
}

interface FormProps {
  initialEmail?: string
  userLocation?: any
  onError?: (error: any) => void
  history?: History
  title: string
  subtitle: string
  theme: ThemeProps<PageTheme>
}

const StyledButton = styled(Button)`
  ${media.sm`
    width: 100%;
  `};
`

const Row = styled.div`
  display: flex;
  margin: 16px 0;
  &:last-of-type {
    margin-top: 48px;
    align-items: center;
  }
  ${media.sm`
    flex-direction: column;
    margin: 0;
    &:last-of-type {
      margin-top: 8px;
    }
  `};
`

const Col = styled.div`
  flex: 1;
  margin: 0 16px;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  label {
    color: ${props => props.theme.textColor};
    font-size: 0.7em;
  }
  & > div {
    border-bottom-color: ${props => props.theme.textColor};
  }
  ${media.sm`
    margin: 8px 0;
    width: 100%;
  `};
`

const SubmitCol = styled(Col)`
  flex-grow: 0;
  justify-self: flex-start;
`

const Form = styled(FormikForm)`
  margin-top: 32px;
`

const ContactFormInputs = ({
  intl,
  isSubmitting,
  errors,
  touched,
  handleBlur,
  title,
  subtitle,
  theme,
}) => (
  <React.Fragment>
    <Heading level="2" size="xxl" color={theme.textColor}>
      {title}
    </Heading>
    {subtitle ? (
      <SubHeading level="3" size="sm" color={theme.textColor}>
        <FormattedMessage
          id="NotProvided"
          defaultMessage={subtitle}
          values={{
            email: (msg: any) => <a href={`mailto:${msg}`}>{msg}</a>,
          }}
        />
      </SubHeading>
    ) : null}
    <Form>
      <Row>
        <Col>
          <FormattedMessage id={'landingpage.contactform.email'}>
            {text => (
              <Field
                name="email"
                render={({ field }: any) => (
                  <Input
                    type={'email'}
                    error={
                      touched.email && !!errors.email
                        ? intl.formatMessage({ id: errors.email })
                        : undefined
                    }
                    name={field.name}
                    variant={'standard'}
                    placeholder={text.toString()}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={handleBlur}
                    hideErrorIcon={!touched.email}
                  />
                )}
              />
            )}
          </FormattedMessage>
        </Col>
        <Col>
          <FormattedMessage id={'landingpage.contactform.name'}>
            {text => (
              <Field
                name="name"
                render={({ field }: any) => (
                  <Input
                    name={field.name}
                    error={
                      touched.name && errors.name
                        ? intl.formatMessage({ id: errors.name })
                        : undefined
                    }
                    variant={'standard'}
                    placeholder={text.toString()}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={handleBlur}
                  />
                )}
              />
            )}
          </FormattedMessage>
        </Col>
        <Col>
          <FormattedMessage id={'landingpage.contactform.phone'}>
            {text => (
              <Field
                name="phone"
                render={({ field }: any) => (
                  <Input
                    type={'text'}
                    name={field.name}
                    error={
                      touched.phone && errors.phone
                        ? intl.formatMessage({ id: errors.phone })
                        : undefined
                    }
                    variant={'standard'}
                    placeholder={text.toString()}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={handleBlur}
                    hideErrorIcon={false}
                  />
                )}
              />
            )}
          </FormattedMessage>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormattedMessage id={'landingpage.contactform.about'}>
            {text => (
              <Field
                name="about"
                render={({ field }: any) => (
                  <Input
                    type={'text'}
                    name={field.name}
                    variant={'standard'}
                    placeholder={text.toString()}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={handleBlur}
                    hideErrorIcon={true}
                  />
                )}
              />
            )}
          </FormattedMessage>
        </Col>
      </Row>
      <Row>
        <SubmitCol>
          <StyledButton
            flat
            text={intl.formatMessage({ id: 'landingpage.contactform.submit' })}
            type={'submit'}
            center
            medium
            disabled={isSubmitting}
          />
        </SubmitCol>
      </Row>
    </Form>
  </React.Fragment>
)

const ContactFormSent = ({ theme, values: { email } }) => (
  <React.Fragment>
    <Heading level="2" size="xxl" color={theme.textColor}>
      <FormattedMessage id="landingpage.contactform.sentTitle" />
    </Heading>
    <SubHeading level="3" size="sm" color={theme.textColor}>
      <FormattedMessage
        id="landingpage.contactform.sentSubheading"
        values={{
          email,
        }}
      />
    </SubHeading>
  </React.Fragment>
)

const ContactFormInner = (
  props: FormikProps<FormValues> & InjectedIntlProps
) => {
  const { status } = props
  return status === 'ready' ? (
    <ContactFormInputs {...props} />
  ) : status === 'sent' ? (
    <ContactFormSent {...props} />
  ) : null
}

const ContactFormHoC = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => {
    return {
      email: '',
      name: '',
      phone: '',
      about: '',
    }
  },
  mapPropsToStatus: () => 'ready',
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('landingpage.contactform.error.validEmail')
      .required('landingpage.contactform.error.requiredEmail'),
    name: Yup.string().required('landingpage.contactform.error.requiredName'),
    phone: Yup.string().required('landingpage.contactform.error.requiredPhone'),
    about: Yup.string(),
  }),
  handleSubmit: (
    { email, name, phone, about },
    { setSubmitting, setStatus }
  ) => {
    const lowercaseEmail = email.toLowerCase()
    storeLead(
      { name, phone, email: lowercaseEmail, description: about },
      contactToSource('Submitted Landing Page Contact Form')
    ).then(() => {
      setStatus('sent')
      setSubmitting(false)
    })
  },
})(injectIntl(ContactFormInner))

const SubHeading = styled(Heading)`
  a {
    color: ${props => props.theme.textColor};
  }
`

const ContactFormWrapper = styled.div`
  ${SubHeading} {
    margin-top: 14px;
  }
`

const ContactForm: React.FC<any> = props => {
  return (
    <ContactFormWrapper>
      <ContactFormHoC {...props} />
    </ContactFormWrapper>
  )
}

export default withTheme(ContactForm)
