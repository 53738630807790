import * as React from 'react'
import { Slider as DefaultSlider } from 'cc-ui-components'
import { convertContentfulImage } from '../DynamicAssembly'
import { injectIntl, InjectedIntlProps } from 'react-intl'

interface Props {
  slides: SliderItem[]
  displayNavDots?: boolean
}

interface SliderItem {
  element: any
}

const Slider: React.SFC<Props & InjectedIntlProps> = ({ slides, intl, ...rest }) => {

  if (!slides || !slides[0] || !slides[0].element || !slides[0].element[0]) {
    return null
  }
  const sliderSlides = slides.filter(({ element }) => element)
  const sliderTypeName = sliderSlides[0].element[0].__typename

  if (sliderTypeName === 'ContentfulModuleTwoColumnRow') {
    sliderSlides.forEach(({ element }) => {
      element[0].elements[0].image = convertContentfulImage(
        element[0].elements[0].image
      )
    })
  } else if (sliderTypeName === 'ContentfulTalent') {
    sliderSlides.forEach(({ element }) => {
      element[0].profilePicture = convertContentfulImage(
        element[0].profilePicture
      )
    })
  } else if (sliderTypeName === 'ContentfulProject') {
    sliderSlides.map(({ element }) => {
      if (element[0].client) {
        element[0].client.logo = convertContentfulImage(element[0].client.logo)
      }
      element[0].urlLabel = intl.formatMessage({
        id: 'clients.project.details',
      })
    })
  } else if (sliderTypeName === 'ContentfulClient') {
    sliderSlides.forEach(({ element }) => {
      element[0].logo = convertContentfulImage(element[0].logo)
    })
  }
  return <DefaultSlider {...rest} slides={sliderSlides} />
}

const IntlSlider = injectIntl(Slider)

export { IntlSlider as Slider }
