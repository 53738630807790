import * as React from 'react'
import DynamicAssembly, { convertContentfulImage } from '../DynamicAssembly'
import { Teaser as DefaultTeaser } from 'cc-ui-components'
import { PageTheme } from '../../styles/config'
import { ThemeProps, withTheme } from 'styled-components'

const Teaser: React.SFC<any & ThemeProps<PageTheme>> = ({
  icon,
  links,
  ...rest
}) => {
  return (
    <DefaultTeaser
      {...rest}
      links={links && links.length && DynamicAssembly(links)}
      icon={convertContentfulImage(icon)}
    />
  )
}

export default withTheme(Teaser)
