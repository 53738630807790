import * as React from 'react'

import Teaser from './page/Teaser'
import TwoColumn from './page/TwoColumn'
import PageHeader from './PageHeader'
import Timeline from './page/Timeline'
import TabContainer from './page/TabContainer'
import FullwidthImage from './page/FullwidthImage'
import {
  FaqSection,
  ColumnWiseContent,
  PriceBox,
  CTA,
  Spacer,
  Talent,
} from 'cc-ui-components'
import { getLanguageCode } from '../styles/utils'
import { Links } from './page/Links'
import Survey from './page/Survey'
import Script from './page/Script'
import { Slider } from './page/Slider'
import { TabbedTeaser } from './page/TabbedTeaser'
import { Usp } from './page/Usp'
import { ImageBar } from './page/ImageBar'
import { Title } from './page/Title'
import { SliderSmall } from './page/SilderSmall'
import { Grid } from './page/Grid'
import { TeaserImage } from './page/TeaserImage'
import { ImageLink } from './page/ImageLink'
import ContactForm from './page/ContactForm'
import SkillsList from './SkillsList'

export interface IconProps {
  file: {
    fileName?: string
    url: string
  }
}

let Components: any = null

export const mapCtaElement = (element: any) => {
  if (element.internalLink && element.internalLink.slug) {
    const langKey = getLanguageCode()
    element.internalLink = `/${langKey}/${element.internalLink.slug}`
  }
  return element
}

const getMapping = () => {
  return {
    ContentfulModuleTitle: Title,
    ContentfulModuleTeaser: Teaser,
    ContentfulModuleTeaserImage: TeaserImage,
    ContentfulModulePageHeader: PageHeader,
    ContentfulModuleFaqSection: FaqSection,
    ContentfulModuleColumnWiseContent: ColumnWiseContent,
    ContentfulModuleTabContainer: TabContainer,
    ContentfulModuleTabbedTeaser: TabbedTeaser,
    ContentfulModuleTimeline: Timeline,
    ContentfulModuleSlider: Slider,
    ContentfulModuleSliderSmall: SliderSmall,
    ContentfulModuleGrid: Grid,
    ContentfulContentUsp: Usp,
    ContentfulModuleCtaGroup: Links,
    ContentfulModulePriceBox: PriceBox,
    ContentfulModuleImageFullwidth: FullwidthImage,
    ContentfulModuleImageBar: ImageBar,
    ContentfulModuleCta: CTA,
    ContentfulModuleTwoColumnRow: TwoColumn,
    ContentfulModuleSurvey: Survey,
    ContentfulModuleScript: Script,
    ContentfulModuleSpacer: Spacer,
    ContentfulTalent: Talent,
    ContentfulModuleImageLink: ImageLink,
    ContentfulModuleContactForm: ContactForm,
    ContentfulModuleSkillsList: SkillsList,
  }
}

// a component that will render one or more components
// that it doesn't explicitly include in it's JSX
export const DynamicAssembly = (children: any) => {
  const childs = children instanceof Array ? children : [children]
  let modules = childs.map(child => {
    if (!child || !child.hasOwnProperty('__typename')) {
      return child
    }
    // Defer components mapping load
    if (!Components) {
      Components = getMapping()
    }
    const Component = Components[child.__typename]

    return Component ? <Component {...child} /> : <div />
  })
  modules = modules.map(
    (element, index) =>
      element ? { ...element, key: `assembly-${index}` } : null
  )
  return modules.length === 1 ? (
    modules[0]
  ) : (
    <React.Fragment>{modules}</React.Fragment>
  )
}

export default DynamicAssembly

export const convertContentfulImage = (image: any) => {
  if (!image) {
    return ''
  }
  if (image.fluid && image.fluid.src) {
    return image.fluid.src
  }
  if (image.file && image.file.url) {
    return image.file.url
  }
  if (image.sizes && image.sizes.src) {
    return image.sizes.src
  }
  if (image.fixed && image.fixed.src) {
    return image.fixed.src
  }
  return image
}
