import * as React from 'react'

import { ThemeProps, withTheme } from 'styled-components'
import { PageTheme } from '../../styles/config'
import { Timeline as TimelineDefault } from 'cc-ui-components'
import DynamicAssembly, { convertContentfulImage } from '../DynamicAssembly'

const Timeline: React.SFC<any & ThemeProps<PageTheme>> = ({
  callToAction,
  steps,
  ...props
}) => (
  <TimelineDefault
    {...props}
    callToAction={DynamicAssembly(callToAction)}
    steps={steps.map((step: any) => ({
      ...step,
      icon: convertContentfulImage(step.icon),
    }))}
  />
)

export default withTheme(Timeline)
