import * as React from 'react'
import { ThemeProps, withTheme } from 'styled-components'

import DynamicAssembly, { convertContentfulImage } from './DynamicAssembly'
import { PageHeader as PageHeaderDefault } from 'cc-ui-components'
import { PageTheme } from '../styles/config'

const PageHeader: React.SFC<any & ThemeProps<PageTheme>> = ({
  children,
  imageOverlay,
  teaser,
  image,
  survey,
  ...rest
}) => (
  <PageHeaderDefault
    {...rest}
    children={DynamicAssembly(children)}
    teaser={DynamicAssembly(teaser)}
    survey={DynamicAssembly(survey)}
    image={convertContentfulImage(image)}
    imageOverlay={convertContentfulImage(imageOverlay)}
  />
)

export default withTheme(PageHeader)
