import * as React from 'react'
import styled, { withTheme } from 'styled-components'
import { PageTheme } from '../../styles/config'
import { colors, media, Heading } from 'cc-ui-components'
import { Link } from 'gatsby'
import { changeNavColor } from '../../styles/utils'
import Waypoint from '@clayne/react-waypoint'

export interface TabItem {
  title: string
  component: any
  headerTitle?: string
  headerSubtitle?: string
  link?: string
  cta?: any
}

interface TabsProps {
  items: TabItem[]
  activeIndex?: number
  colorsInverse?: boolean
  hideHeader?: boolean
  theme?: PageTheme
}

interface TabsState {
  activeTab: number
}

interface TabProps {
  active: boolean
  activeColor: string
  secondaryColor: string
  colorsInverse?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const HeaderContainer = styled.div`
  width: 100%;
  min-height: 677px;
  background-color: ${colors.darkBackground};
  display: flex;
  justify-content: center;
  padding-top: 220px;
  ${media.sm`
    min-height: calc(45vh + 100px);
    padding-top: 100px;
  `};
`

const PaddingTop = styled.div`
  height: 10px;
`

const Header = styled.div`
  width: 40%;
  height: 50%;
  background-color: ${colors.darkBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    text-align: center;
  }
  ${media.sm`
    width: 90%;
    h1 {
      margin-bottom: 1em;
    }
   `};
`

const NavTab = styled.div`
  width: 156px;
  height: 54px;
  border: 1px solid ${colors.inverseText};
  border-bottom: 0;
  cursor: pointer;
  user-select: none;
  color: ${(props: TabProps) =>
    props.active ? props.secondaryColor : props.activeColor};
  background-color: ${(props: TabProps) =>
    props.active ? props.activeColor : props.secondaryColor};
  ${(props: TabProps) =>
    props.active
      ? ''
      : `border-bottom: 1px solid ${
          props.colorsInverse ? props.activeColor : props.secondaryColor
        }`};
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  a {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  ${media.sm`
    height: 40px;
  `};
`

const NavTabContainer = styled.div`
  margin-top: -54px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${media.sm`
    margin-top: -40px;
    width: calc(100% + 30px);
    margin-left: -15px;
  `};
`

const Padding = styled.div`
  flex: 1;
  border-bottom: 1px solid ${colors.bodyBackground};
  display: flex;
`

const CTAHeaderWrapper = styled.div`
  margin-top: 1.5rem;
`

class Tabs extends React.PureComponent<TabsProps, TabsState> {
  constructor(props: TabsProps) {
    super(props)
    this.state = {
      activeTab:
        props.activeIndex && props.items.length - 1 <= props.activeIndex
          ? props.activeIndex
          : 0,
    }
  }

  public renderHeader(tab: TabItem) {
    return (
      <Waypoint
        onEnter={() => changeNavColor('black')}
        topOffset={'0'}
        bottomOffset={'90%'}
        scrollableAncestor={'window'}
      >
        <HeaderContainer>
          <Header>
            <Heading
              level="1"
              size="xxl"
              trailer={1}
              color={colors.inverseText}
            >
              {tab.headerTitle || ''}
            </Heading>
            <Heading level="1" size="md" trailer={1} color={colors.lightText}>
              {tab.headerSubtitle || ''}
            </Heading>
            <CTAHeaderWrapper>{tab.cta}</CTAHeaderWrapper>
          </Header>
        </HeaderContainer>
      </Waypoint>
    )
  }

  public renderNavTab = (tab: TabItem, index: number) => {
    const { colorsInverse, theme } = this.props
    const activeColor = colorsInverse
      ? theme && theme.backgroundColor === colors.lightBackground
        ? colors.lightBackground
        : colors.inverseText
      : colors.darkBackground
    const secondaryColor = colorsInverse ? colors.text : colors.inverseText
    return (
      <NavTab
        active={index === this.state.activeTab}
        onClick={() => this.setState({ activeTab: index })}
        key={index}
        activeColor={activeColor}
        secondaryColor={secondaryColor}
        colorsInverse={colorsInverse}
      >
        {tab.link && <Link to={tab.link} />}
        {tab.title}
      </NavTab>
    )
  }

  public renderNav = () => (
    <NavTabContainer>
      <Padding />
      {this.props.items.map(this.renderNavTab)}
      <Padding />
    </NavTabContainer>
  )

  public render() {
    const { items, hideHeader } = this.props
    const { activeTab } = this.state

    return (
      <Wrapper>
        {!hideHeader ? this.renderHeader(items[activeTab]) : <PaddingTop />}
        {this.renderNav()}
        {items[activeTab].component}
      </Wrapper>
    )
  }
}

export default withTheme(Tabs)
