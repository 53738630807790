import * as React from 'react'
import { ImageBar as DefaultImageBar } from 'cc-ui-components'
import { convertContentfulImage } from '../DynamicAssembly'

export const ImageBar: React.SFC<any> = ({ images, ...rest }) => {
  if (!images || !images.length) {
    return null
  }
  return (
    <DefaultImageBar {...rest} images={images.map(convertContentfulImage)} />
  )
}
