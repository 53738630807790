import * as React from 'react'

import DynamicAssembly from '../DynamicAssembly'
import { ThemeProps, withTheme } from 'styled-components'
import { PageTheme } from '../../styles/config'
import { TwoColumn as TwoColumnDefault } from 'cc-ui-components'

const TwoColumn: React.SFC<any & ThemeProps<PageTheme>> = ({
  elements,
  ...props
}) => <TwoColumnDefault {...props} elements={elements.map(DynamicAssembly)} />

export default withTheme(TwoColumn)
