import * as React from 'react'
import Tabs from '../common/Tabs'
import styled, { ThemeProps, withTheme } from 'styled-components'
import DynamicAssembly from '../DynamicAssembly'
import { PageTheme } from '../../styles/config'
import { colors, media } from 'cc-ui-components'

import { Location } from '@reach/router'
import { getCurrentLangKey } from 'ptz-i18n'
const languages = require('../../data/languages')

interface TabElementProps {
  title: string
  contentModules?: any
  internalLink?: any
  externalLink?: string
}

interface TabContainerProps {
  tabElements: TabElementProps[]
  title?: string
}

interface WrapperProps {
  textColor: string
}

const Wrapper = styled.div`
  color: ${(props: WrapperProps) => props.textColor || colors.text};
`

const TabContainerWrapper = styled.div`
  margin-top: -47px !important;
  ${media.sm`
    margin-top: -1px !important;
  `};
`

const TabContainer: React.SFC<TabContainerProps & ThemeProps<PageTheme>> = ({
  title,
  tabElements,
  theme,
}) => {
  return (
    <Location>
      {({ location }) => {
        const { langs, defaultLangKey } = languages
        const langKey = getCurrentLangKey(
          langs,
          defaultLangKey,
          location.pathname
        )
        const tabItems = tabElements.map(element => ({
          title: element.title,
          link: element.internalLink
            ? `${langKey}/${element.internalLink.slug}`
            : undefined,
          component: (
            <Wrapper textColor={theme.textColor}>
              {DynamicAssembly(element.contentModules)}
            </Wrapper>
          ),
        }))
        return (
          <TabContainerWrapper id={title}>
            <Tabs items={tabItems} colorsInverse hideHeader />
          </TabContainerWrapper>
        )
      }}
    </Location>
  )
}

export default withTheme(TabContainer)
