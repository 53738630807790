import * as React from 'react'

interface Script {
  script: {
    script: string
  }
  scriptUrl?: string
  markup: {
    markup?: string
  }
}

class Script extends React.Component<Script> {
  componentDidMount(): void {
    if (this.props.scriptUrl && this.props.scriptUrl) {
      const scriptUrlTag = document.createElement('script')
      scriptUrlTag.src = this.props.scriptUrl
      scriptUrlTag.async = false
      if (document.head) {
        document.body.append(scriptUrlTag)
      }
    }

    if (this.props.script && this.props.script.script) {
      const script = this.props.script.script
      const scriptTag = document.createElement('script')

      scriptTag.innerHTML = script
      scriptTag.defer = true

      document.body.appendChild(scriptTag)
    }
  }

  render() {
    if (this.props.markup) {
      return (
        <div dangerouslySetInnerHTML={{ __html: this.props.markup.markup }} />
      )
    }
    return null
  }
}

export default Script
