module.exports = [
  { name: 'ActionScript', url: '/en/developer/actionscript-developer' },
  { name: 'AFNetworking', url: '/en/developer' },
  { name: 'Agile', url: '/en/developer' },
  { name: 'AJAX', url: '/en/developer/ajax-developer' },
  { name: 'Akka', url: '/en/developer' },
  { name: 'Alexa', url: '/en/developer' },
  { name: 'Amazon Web Services', url: '/en/aws-developer' },
  { name: 'Android', url: '/en/android-developer' },
  { name: 'Angular', url: '/en/angular-developer' },
  { name: 'Angular.js', url: '/en/developer/angular-js-developer' },
  { name: 'Animation', url: '/en/developer' },
  { name: 'Ansible', url: '/en/developer' },
  { name: 'Apache', url: '/en/developer/apache-developer' },
  { name: 'Apache Ant', url: '/en/developer/apache-ant-developer' },
  { name: 'Apache Cassandra', url: '/en/developer/apache-cassandra-developer' },
  { name: 'Apache Cordova', url: '/en/developer/apache-cordova-developer' },
  { name: 'Apache Groovy', url: '/en/developer/apache-groovy-developer' },
  { name: 'Apache HBase', url: '/en/developer' },
  { name: 'Apache Solr', url: '/en/developer' },
  { name: 'Apache Spark', url: '/en/developer/apache-spark-developer' },
  { name: 'Apex', url: '/en/developer' },
  { name: 'App Development', url: '/en/app-developer' },
  { name: 'Arduino', url: '/en/developer/arduino-developer' },
  { name: 'Asana', url: '/en/developer' },
  { name: 'ASP.NET', url: '/en/developer/asp-net-developer' },
  { name: 'ASP.NET MVC', url: '/en/developer' },
  { name: 'Aurelia', url: '/en/developer' },
  { name: 'AWS RDS', url: '/en/developer' },
  { name: 'Babel', url: '/en/developer/babel-developer' },
  { name: 'Backbone.js', url: '/en/developer/backbone-js-developer' },
  { name: 'Backend', url: '/en/backend-developer' },
  { name: 'Basecamp', url: '/en/developer/basecamp-developer' },
  { name: 'Bash', url: '/en/developer/bash-developer' },
  { name: 'Big Data', url: '/en/developer/big-data-developer' },
  { name: 'Blockchain', url: '/en/developer/blockchain-developer' },
  { name: 'Bootstrap', url: '/en/developer/bootstrap-developer' },
  { name: 'C', url: '/en/c-developer' },
  { name: 'C#', url: '/en/c-sharp-developer' },
  { name: 'C++', url: '/en/c-plus-plus-developer' },
  { name: 'CakePHP', url: '/en/developer' },
  { name: 'CentOS', url: '/en/developer' },
  { name: 'Clojure', url: '/en/developer/clojure-developer' },
  { name: 'Cloud Computing', url: '/en/developer' },
  { name: 'CMS', url: '/en/developer/cms-developer' },
  { name: 'Cocoa Touch', url: '/en/developer/cocoa-touch-developer' },
  { name: 'Codelgniter', url: '/en/developer' },
  { name: 'CoffeeScript', url: '/en/developer/coffeescript-developer' },
  { name: 'Cordova', url: '/en/developer' },
  { name: 'CouchDB', url: '/en/developer/couchdb-developer' },
  { name: 'CSS', url: '/en/css-developer' },
  { name: 'D3.js', url: '/en/developer/d3-js-developer' },
  { name: 'Database Development', url: '/en/developer' },
  { name: 'Data Visualization', url: '/en/developer' },
  { name: 'Debian Linux', url: '/en/developer' },
  { name: 'DevOps', url: '/en/developer/devops-developer/' },
  { name: 'Delphi', url: '/en/developer/delphi-developer' },
  { name: 'Django', url: '/en/django-developer' },
  { name: 'Docker', url: '/en/developer/docker-developer' },
  { name: 'Drupal', url: '/en/drupal-developer' },
  { name: 'E-Commerce', url: '/en/developer/e-commerce-developer' },
  { name: 'Eclipse', url: '/en/developer/eclipse-developer' },
  { name: 'ECMAScript', url: '/en/developer/ecmascript-developer' },
  { name: 'Elasticsearch', url: '/en/developer/elasticsearch-developer' },
  { name: 'Elixir', url: '/en/developer/elixir-developer' },
  { name: 'Elm', url: '/en/developer/elm-developer/' },
  { name: 'Emacs Lisp', url: '/en/developer/emacs-lisp-developer' },
  { name: 'Ember.js', url: '/en/developer/ember-js-developer' },
  { name: 'Erlang', url: '/en/developer' },
  { name: 'Express', url: '/en/developer/express-developer' },
  { name: 'Ext JS', url: '/en/developer' },
  { name: 'Facebook Open Graph API', url: '/en/developer' },
  { name: 'Firebase', url: '/en/developer/firebase-developer' },
  { name: 'Flash', url: '/en/developer/flash-developer' },
  { name: 'Flask', url: '/en/developer/flask-developer' },
  { name: 'Flex', url: '/en/developer/flex-developer' },
  { name: 'Foundation', url: '/en/developer/foundation-developer' },
  { name: 'Frontend', url: '/en/frontend-developer' },
  { name: 'Full Stack', url: '/en/full-stack-developer' },
  { name: 'Ghost', url: '/en/developer/ghost-developer' },
  { name: 'Git', url: '/en/developer' },
  { name: 'GitHub', url: '/en/developer' },
  { name: 'Go', url: '/en/developer/go-developer' },
  { name: 'Google API', url: '/en/developer' },
  { name: 'Grails', url: '/en/developer' },
  { name: 'Graphics', url: '/en/developer/graphics-developer' },
  { name: 'Gradle', url: '/en/developer/gradle-developer' },
  { name: 'Grunt', url: '/en/developer/grunt-developer' },
  { name: 'Gulp', url: '/en/developer/gulp-developer' },
  { name: 'Gulp.js', url: '/en/developer/gulp-js-developer' },
  { name: 'GWT', url: '/en/developer' },
  { name: 'H2', url: '/en/developer/h2-developer' },
  { name: 'HAML', url: '/en/developer' },
  { name: 'Creative Direction', url: '/en/developer' },
  { name: 'Handlebars', url: '/en/developer' },
  { name: 'Haskell', url: '/en/developer/haskell-developer' },
  { name: 'Heroku', url: '/en/developer/heroku-developer' },
  { name: 'Hibernate', url: '/en/developer/hibernate-developer' },
  { name: 'HTML', url: '/en/developer/html-developer' },
  { name: 'HTML5', url: '/en/developer/html5-developer' },
  { name: 'HTTP', url: '/en/developer' },
  { name: 'IMB DB2', url: '/en/developer/imb-db2-developer' },
  { name: 'Integration', url: '/en/developer/integration-developer' },
  { name: 'IntelliJ IDEA', url: '/en/developer/intellij-idea-developer' },
  { name: 'InVision', url: '/en/developer' },
  { name: 'Ionic', url: '/en/developer/ionic-developer' },
  { name: 'iOS', url: '/en/ios-developer' },
  { name: 'Jasmine', url: '/en/developer/jasmine-developer' },
  { name: 'Java', url: '/en/java-developer' },
  { name: 'Java EE', url: '/en/developer/java-ee-developer' },
  { name: 'JavaScript', url: '/en/javascript-developer' },
  { name: 'Jenkins', url: '/en/developer/jenkins-developer' },
  { name: 'Jira', url: '/en/developer' },
  { name: 'Joomla', url: '/en/developer/joomla-developer' },
  { name: 'JPA', url: '/en/developer/jpa-developer' },
  { name: 'jQuery', url: '/en/jquery-developer' },
  { name: 'jsf', url: '/en/developer/jsf-developer' },
  { name: 'JSON', url: '/en/developer/json-developer' },
  { name: 'JSP', url: '/en/developer/jsp-developer' },
  { name: 'JUnit', url: '/en/developer/junit-developer' },
  { name: 'Kafka', url: '/en/developer/kafka-developer' },
  { name: 'Karma', url: '/en/developer/karma-developer/' },
  { name: 'KnockoutJS', url: '/en/developer/knockoutjs-developer' },
  { name: 'Kotlin', url: '/en/developer/kotlin-developer' },
  { name: 'Kubernetes', url: '/en/developer/kubernetes-developer/' },
  { name: 'LAMP', url: '/en/developer/lamp-developer' },
  { name: 'Laravel', url: '/en/laravel-developer' },
  { name: 'LaTeX', url: '/en/developer/latex-developer' },
  { name: 'LESS', url: '/en/developer/less-developer/' },
  { name: 'LevelDB', url: '/en/developer/leveldb-developer' },
  { name: 'Lightning.js', url: '/en/developer' },
  { name: 'LINQ', url: '/en/developer/linq-developer' },
  { name: 'Linux', url: '/en/developer' },
  { name: 'Lua', url: '/en/developer/lua-developer/' },
  { name: 'Machine Learning', url: '/en/developer' },
  { name: 'Magento', url: '/en/developer/magento-developer' },
  { name: 'Maya', url: '/en/developer' },
  { name: 'MariaDB', url: '/en/developer/mariadb-developer/' },
  { name: 'MATLAB', url: '/en/developer/matlab-developer' },
  { name: 'Maven', url: '/en/developer/maven-developer/' },
  { name: 'Memcached', url: '/en/developer/memcached-developer/' },
  { name: 'Meteor', url: '/en/developer/meteor-developer/' },
  {
    name: 'Microsoft Access',
    url: '/en/developer/microsoft-access-developer/',
  },
  { name: 'Microsoft SQL', url: '/en/developer' },
  { name: 'Mocha', url: '/en/developer/mocha-developer' },
  { name: 'MongoDB', url: '/en/developer/mongodb-developer' },
  { name: 'MPI', url: '/en/developer' },
  { name: 'Mustache.js', url: '/en/developer' },
  { name: 'MVC', url: '/en/developer/mvc-developer' },
  { name: 'MySQL', url: '/en/developer/mysql-developer' },
  { name: '.NET', url: '/en/developer' },
  { name: 'Neo4j', url: '/en/developer/neo4j-developer' },
  { name: 'Nginx', url: '/en/developer/nginx-developer/' },
  { name: 'NHibernate', url: '/en/developer' },
  { name: 'NLTK', url: '/en/developer' },
  { name: 'Node.js', url: '/en/node-js-developer' },
  { name: 'NoSQL', url: '/en/developer/nosql-developer' },
  { name: 'Objective-C', url: '/en/objective-c-developer' },
  { name: 'OOP', url: '/en/developer/oop-developer' },
  { name: 'OpenCart', url: '/en/developer/opencart-developer' },
  { name: 'OpenCV', url: '/en/developer' },
  { name: 'OpenGL', url: '/en/developer/opengl-developer' },
  { name: 'Open Source', url: '/en/developer' },
  { name: 'Oracle', url: '/en/developer/oracle-developer/' },
  { name: 'Parse', url: '/en/developer' },
  { name: 'Pascal', url: '/en/developer/pascal-developer' },
  { name: 'Perl', url: '/en/developer/perl-developer' },
  { name: 'Phoenix', url: '/en/developer' },
  { name: 'PhoneGap', url: '/en/developer/phonegap-developer/' },
  { name: 'PHP', url: '/en/php-developer' },
  { name: 'PHPUnit', url: '/en/developer/phpunit-developer/' },
  { name: 'PL/SQL', url: '/en/developer/pl/sql-developer' },
  { name: 'Polymer', url: '/en/developer/polymer-developer/' },
  { name: 'PostgreSQL', url: '/en/developer/postgresql-developer/' },
  { name: 'PrestaShop', url: '/en/developer/prestashop-developer/' },
  { name: 'Product Design', url: '/en/developer' },
  { name: 'Prototype.js', url: '/en/developer' },
  { name: 'Protractor', url: '/en/developer/protractor-developer' },
  { name: 'PWA', url: '/en/developer' },
  { name: 'Python', url: '/en/python-developer' },
  { name: 'Qt', url: '/en/developer/qt-developer' },
  { name: 'R', url: '/en/developer/r-developer' },
  { name: 'RabbitMQ', url: '/en/developer/rabbitmq-developer/' },
  { name: 'React', url: '/en/developer/react-developer/' },
  { name: 'React.js', url: '/en/reactjs-developer' },
  { name: 'React Native', url: '/en/developer/react-native-developer' },
  { name: 'Realm', url: '/en/developer/realm-developer' },
  { name: 'Redis', url: '/en/developer/redis-developer/' },
  { name: 'Redux', url: '/en/redux-developer' },
  { name: 'REST', url: '/en/developer/rest-developer/' },
  { name: 'RESTful', url: '/en/developer/restful-developer/' },
  { name: 'RethinkDB', url: '/en/developer/rethinkdb-developer/' },
  { name: 'Retrofit', url: '/en/developer' },
  { name: 'RSpec', url: '/en/developer/rspec-developer' },
  { name: 'Ruby', url: '/en/developer/ruby-developer' },
  { name: 'Ruby on Rails', url: '/en/ruby-on-rails-developer' },
  { name: 'Rust', url: '/en/developer' },
  { name: 'Sails.js', url: '/en/developer' },
  { name: 'SASS', url: '/en/developer/sass-developer' },
  { name: 'Salesforce', url: '/en/developer/salesforce-developer' },
  { name: 'Salesforce API', url: '/en/developer' },
  { name: 'Scala', url: '/en/developer/scala-developer' },
  { name: 'Scrum', url: '/en/developer/scrum-developer' },
  { name: 'SCSS', url: '/en/developer/scss-developer' },
  { name: 'Shell Script', url: '/en/developer' },
  { name: 'Shopify', url: '/en/developer/shopify-developer' },
  { name: 'Sinatra', url: '/en/developer/sinatra-developer' },
  { name: 'Sinon.js', url: '/en/developer' },
  { name: 'Sketch', url: '/en/developer' },
  { name: 'Smarty', url: '/en/developer' },
  { name: 'SOA', url: '/en/developer/soa-developer' },
  { name: 'SOAP', url: '/en/developer/soap-developer' },
  { name: 'Socket.IO', url: '/en/developer/socket-io-developer' },
  { name: 'Software Architecture', url: '/en/developer' },
  { name: 'Software Development', url: '/en/developer' },
  { name: 'Solr', url: '/en/developer/solr-developer' },
  { name: 'Spark', url: '/en/developer/spark-developer' },
  { name: 'Spring', url: '/en/developer/spring-developer' },
  { name: 'Spring Boot', url: '/en/developer/spring-boot-developer' },
  { name: 'SQL', url: '/en/sql-developer' },
  { name: 'SQL-99', url: '/en/developer' },
  { name: 'SQLite', url: '/en/developer/sqlite-developer' },
  { name: 'Subversion', url: '/en/developer/subversion-developer/' },
  { name: 'Swift', url: '/en/swift-developer' },
  { name: 'Symfony', url: '/en/developer/symfony-developer' },
  { name: 'TDD', url: '/en/developer/tdd-developer' },
  { name: 'TensorFlow', url: '/en/developer/tensorflow-developer' },
  { name: 'Three.js', url: '/en/developer' },
  { name: 'Titanium', url: '/en/developer' },
  { name: 'T-SQL', url: '/en/developer/t-sql-developer/' },
  { name: 'Trello', url: '/en/developer' },
  { name: 'TypeScript', url: '/en/developer/typescript-developer' },
  { name: 'Twig.js', url: '/en/developer' },
  { name: 'Twilio API', url: '/en/developer' },
  { name: 'TYPO3', url: '/en/developer/typo3-developer' },
  { name: 'Ubuntu', url: '/en/developer/ubuntu-developer' },
  { name: 'UI', url: '/en/developer/ui-developer' },
  { name: 'UIKit', url: '/en/developer/uikit-developer' },
  { name: 'UML', url: '/en/developer/uml-developer' },
  { name: 'Underscore.js', url: '/en/developer' },
  { name: 'Unix', url: '/en/developer/unix-developer' },
  { name: 'Unity', url: '/en/developer/unity-developer' },
  { name: 'UX Design', url: '/en/developer/ux-developer' },
  { name: 'Vaadin', url: '/en/developer' },
  { name: 'Vagrant', url: '/en/developer/vagrant-developer' },
  { name: 'VB.NET', url: '/en/developer' },
  { name: 'Visual Basic', url: '/en/developer/visual-basic-developer/' },
  { name: 'Visual Studio', url: '/en/developer/visual-studio-developer' },
  { name: 'Vue.js', url: '/en/vue-js-developer' },
  { name: 'WCF', url: '/en/developer/wcf-developer' },
  { name: 'Web Development', url: '/en/developer' },
  { name: 'Web Services', url: '/en/developer/web-services-developer' },
  { name: 'Webpack', url: '/en/developer/webpack-developer' },
  { name: 'WinForms', url: '/en/developer' },
  { name: 'Wireframe', url: '/en/developer/wireframe-developer/' },
  { name: 'WooCommerce', url: '/en/woocommerce-developer' },
  { name: 'WordPress', url: '/en/wordpress-developer' },
  { name: 'WPF', url: '/en/developer/wpf-developer/' },
  { name: 'Xamarin', url: '/en/developer/xamarin-developer' },
  { name: 'Xcode', url: '/en/developer/xcode-developer' },
  { name: 'XHTML', url: '/en/developer/xhtml-developer' },
  { name: 'XML', url: '/en/developer/xml-developer/' },
  { name: 'XPath', url: '/en/developer' },
  { name: 'XSLT', url: '/en/developer/xslt-developer' },
  { name: 'Yii', url: '/en/developer/yii-developer' },
  { name: 'YouTube API', url: '/en/developer' },
  { name: 'Zend', url: '/en/developer/zend-developer' },
  { name: 'ZURB', url: '/en/developer' },
]
