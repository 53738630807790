import * as React from 'react'
import { SliderSmall as DefaultSliderSmall } from 'cc-ui-components'
import { convertContentfulImage } from '../DynamicAssembly'

export const SliderSmall: React.SFC<any> = ({ slideElements, ...rest }) => {
  if (!slideElements || !slideElements.length) {
    return null
  }
  return (
    <DefaultSliderSmall
      {...rest}
      slideElements={slideElements.map((element: any) => ({
        ...element,
        profilePicture: convertContentfulImage(element.profilePicture),
      }))}
    />
  )
}
