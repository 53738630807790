import * as React from 'react'
import { Survey as BaseSurvey } from 'cc-ui-components'
import { injectIntl, InjectedIntlProps } from 'react-intl'
import { storeSurvey, surveyToSource, storeLead } from '../../lib/codecontrol'

class Survey extends React.Component<InjectedIntlProps> {
  strings = {
    ctaButton: this.props.intl.formatMessage({ id: 'survey.ctaButton' }),
    reset: this.props.intl.formatMessage({ id: 'survey.reset' }),
    progress: this.props.intl.formatMessage({ id: 'survey.progress' }),
    previousButton: this.props.intl.formatMessage({
      id: 'survey.previousButton',
    }),
    contactInfo: this.props.intl.formatMessage({ id: 'survey.contactInfo' }),
    placeholderName: this.props.intl.formatMessage({
      id: 'survey.placeholderName',
    }),
    placeholderEmail: this.props.intl.formatMessage({
      id: 'survey.placeholderEmail',
    }),
    placeholderPhone: this.props.intl.formatMessage({ id: 'global.phone' }),
    placeholderCompany: this.props.intl.formatMessage({
      id: 'survey.placeholderCompany',
    }),
    continueButton: this.props.intl.formatMessage({
      id: 'survey.continueButton',
    }),
    outroTitle: this.props.intl.formatMessage({ id: 'survey.outroTitle' }),
    outroDescriptionOne: this.props.intl.formatMessage({
      id: 'survey.outroDescriptionOne',
    }),
    outroDescriptionTwo: this.props.intl.formatMessage({
      id: 'survey.outroDescriptionTwo',
    }),
    outroSubtitle: this.props.intl.formatMessage({
      id: 'survey.outroSubtitle',
    }),
  }

  submitSurvey = ({ responses, contact }: any) => {
    return storeSurvey(responses).then(surveyDoc => {
      return storeLead(
        contact,
        surveyToSource(surveyDoc.id, 'Submitted Survey')
      )
    })
  }

  render() {
    return (
      <BaseSurvey
        {...this.props}
        submitSurvey={this.submitSurvey}
        strings={this.strings}
      />
    )
  }
}

export default injectIntl(Survey) // { Survey }
