module.exports = [
  { name: 'ActionScript', url: '/de/entwickler/actionscript-entwickler' },
  { name: 'AFNetworking', url: '/de/entwickler' },
  { name: 'Agile', url: '/de/entwickler' },
  { name: 'AJAX', url: '/de/entwickler/ajax-entwickler' },
  { name: 'Akka', url: '/de/entwickler' },
  { name: 'Alexa', url: '/de/entwickler' },
  { name: 'Amazon Web Services', url: '/de/aws-entwickler' },
  { name: 'Android', url: '/de/android-entwickler' },
  { name: 'Angular', url: '/de/angular-entwickler' },
  { name: 'Angular.js', url: '/de/entwickler/angular-js-entwickler' },
  { name: 'Animation', url: '/de/entwickler' },
  { name: 'Ansible', url: '/de/entwickler' },
  { name: 'Apache', url: '/de/entwickler/apache-entwickler/' },
  { name: 'Apache Ant', url: '/de/entwickler/apache-ant-entwickler/' },
  {
    name: 'Apache Cassandra',
    url: '/de/entwickler/apache-cassandra-entwickler/',
  },
  { name: 'Apache Cordova', url: '/de/entwickler/apache-cordova-entwickler' },
  { name: 'Apache Groovy', url: '/de/entwickler/apache-groovy-entwickler' },
  { name: 'Apache HBase', url: '/de/entwickler' },
  { name: 'Apache Solr', url: '/de/entwickler' },
  { name: 'Apache Spark', url: '/de/entwickler/apache-spark-entwickler' },
  { name: 'Apex', url: '/de/entwickler' },
  { name: 'App-Entwicklung', url: '/de/app-entwickler' },
  { name: 'Arduino', url: '/de/entwickler/arduino-entwickler/' },
  { name: 'Asana', url: '/de/entwickler' },
  { name: 'ASP.NET', url: '/de/entwickler/asp-net-entwickler' },
  { name: 'ASP.NET MVC', url: '/de/entwickler' },
  { name: 'Aurelia', url: '/de/entwickler' },
  { name: 'AWS RDS', url: '/de/entwickler' },
  { name: 'Babel', url: '/de/entwickler/babel-entwickler/' },
  { name: 'Backbone.js', url: '/de/entwickler/backbone-js-entwickler/' },
  { name: 'Backend', url: '/de/backend-entwickler' },
  { name: 'Basecamp', url: '/de/entwickler/basecamp-entwickler/' },
  { name: 'Bash', url: '/de/entwickler/bash-entwickler/' },
  { name: 'Big Data', url: '/de/entwickler/big-data-entwickler' },
  { name: 'Blockchain', url: '/de/entwickler/blockchain-entwickler/' },
  { name: 'Bootstrap', url: '/de/entwickler/bootstrap-entwickler/' },
  { name: 'C', url: '/de/c-entwickler' },
  { name: 'C#', url: '/de/c-sharp-entwickler' },
  { name: 'C++', url: '/de/c-plus-plus-entwickler' },
  { name: 'CakePHP', url: '/de/entwickler' },
  { name: 'CentOS', url: '/de/entwickler' },
  { name: 'Clojure', url: '/de/entwickler/clojure-entwickler' },
  { name: 'Cloud Computing', url: '/de/entwickler' },
  { name: 'CMS', url: '/de/entwickler/cms-entwickler' },
  { name: 'Cocoa Touch', url: '/de/entwickler/cocoa-touch-entwickler' },
  { name: 'Codelgniter', url: '/de/entwickler' },
  { name: 'CoffeeScript', url: '/de/entwickler/coffeescript-entwickler' },
  { name: 'Cordova', url: '/de/entwickler' },
  { name: 'CouchDB', url: '/de/entwickler/couchdb-entwickler' },
  { name: 'CSS', url: '/de/css-entwickler' },
  { name: 'D3.js', url: '/de/entwickler/d3-js-entwickler/' },
  { name: 'Datenbankentwicklung', url: '/de/entwickler' },
  { name: 'Datenvisualisierung', url: '/de/entwickler' },
  { name: 'Debian Linux', url: '/de/entwickler' },
  { name: 'DevOps', url: '/de/entwickler/devops-entwickler' },
  { name: 'Delphi', url: '/de/entwickler/delphi-entwickler' },
  { name: 'Django', url: '/de/django-entwickler' },
  { name: 'Docker', url: '/de/entwickler/docker-entwickler/' },
  { name: 'Drupal', url: '/de/drupal-entwickler' },
  { name: 'E-Commerce', url: '/de/entwickler/e-commerce-entwickler' },
  { name: 'Eclipse', url: '/de/entwickler/eclipse-entwickler' },
  { name: 'ECMAScript', url: '/de/entwickler/ecmascript-entwickler/' },
  { name: 'Elasticsearch', url: '/de/entwickler/elasticsearch-entwickler' },
  { name: 'Elixir', url: '/de/entwickler/elixir-entwickler' },
  { name: 'Elm', url: '/de/entwickler/elm-entwickler' },
  { name: 'Emacs Lisp', url: '/de/entwickler/emacs-lisp-entwickler/' },
  { name: 'Ember.js', url: '/de/entwickler/ember-js-entwickler' },
  { name: 'Erlang', url: '/de/entwickler' },
  { name: 'Express', url: '/de/entwickler/express-entwickler' },
  { name: 'Ext JS', url: '/de/entwickler' },
  { name: 'Facebook Open Graph API', url: '/de/entwickler' },
  { name: 'Firebase', url: '/de/entwickler/firebase-entwickler' },
  { name: 'Flash', url: '/de/entwickler/flash-entwickler' },
  { name: 'Flask', url: '/de/entwickler/flask-entwickler' },
  { name: 'Flex', url: '/de/entwickler/flex-entwickler/' },
  { name: 'Foundation', url: '/de/entwickler/foundation-entwickler/' },
  { name: 'Frontend', url: '/de/frontend-entwickler' },
  { name: 'Full Stack', url: '/de/full-stack-entwickler' },
  { name: 'Ghost', url: '/de/entwickler/ghost-entwickler/' },
  { name: 'Git', url: '/de/entwickler' },
  { name: 'GitHub', url: '/de/entwickler' },
  { name: 'Go', url: '/de/entwickler/go-entwickler/' },
  { name: 'Google API', url: '/de/entwickler' },
  { name: 'Grails', url: '/de/entwickler' },
  { name: 'Graphics', url: '/de/entwickler/graphics-entwickler' },
  { name: 'Gradle', url: '/de/entwickler/gradle-entwickler' },
  { name: 'Grunt', url: '/de/entwickler/grunt-entwickler' },
  { name: 'Gulp', url: '/de/entwickler/gulp-entwickler/' },
  { name: 'Gulp.js', url: '/de/entwickler/gulp-js-entwickler/' },
  { name: 'GWT', url: '/de/entwickler' },
  { name: 'H2', url: '/de/entwickler/h2-entwickler' },
  { name: 'HAML', url: '/de/entwickler' },
  { name: 'Creative Direction', url: '/de/entwickler' },
  { name: 'Handlebars', url: '/de/entwickler' },
  { name: 'Haskell', url: '/de/entwickler/haskell-entwickler' },
  { name: 'Heroku', url: '/de/entwickler/heroku-entwickler/' },
  { name: 'Hibernate', url: '/de/entwickler/hibernate-entwickler' },
  { name: 'HTML', url: '/de/html-entwickler' },
  { name: 'HTML5', url: '/de/entwickler/html5-entwickler' },
  { name: 'HTTP', url: '/de/entwickler' },
  { name: 'IMB DB2', url: '/de/entwickler/imb-db2-entwickler/' },
  { name: 'Integration', url: '/de/entwickler/integration-entwickler/' },
  { name: 'IntelliJ IDEA', url: '/de/entwickler/intellij-idea-entwickler/' },
  { name: 'InVision', url: '/de/entwickler' },
  { name: 'Ionic', url: '/de/entwickler/ionic-entwickler/' },
  { name: 'iOS', url: '/de/ios-entwickler' },
  { name: 'Jasmine', url: '/de/entwickler/jasmine-entwickler' },
  { name: 'Java', url: '/de/java-entwickler' },
  { name: 'Java EE', url: '/de/entwickler/java-ee-entwickler/' },
  { name: 'JavaScript', url: '/de/javascript-entwickler' },
  { name: 'Jenkins', url: '/de/entwickler/jenkins-entwickler' },
  { name: 'Jira', url: '/de/entwickler' },
  { name: 'Joomla', url: '/de/entwickler/joomla-entwickler' },
  { name: 'JPA', url: '/de/entwickler/jpa-entwickler/' },
  { name: 'jQuery', url: '/de/jquery-entwickler' },
  { name: 'jsf', url: '/de/entwickler/jsf-entwickler' },
  { name: 'JSON', url: '/de/entwickler/json-entwickler' },
  { name: 'JSP', url: '/de/entwickler/jsp-entwickler' },
  { name: 'JUnit', url: '/de/entwickler/junit-entwickler' },
  { name: 'Kafka', url: '/de/entwickler/kafka-entwickler' },
  { name: 'Karma', url: '/de/entwickler/karma-entwickler' },
  { name: 'KnockoutJS', url: '/de/entwickler/knockoutjs-entwickler' },
  { name: 'Kotlin', url: '/de/entwickler/kotlin-entwickler' },
  { name: 'Kubernetes', url: '/de/entwickler/kubernetes-entwickler' },
  { name: 'LAMP', url: '/de/entwickler/lamp-entwickler/' },
  { name: 'Laravel', url: '/de/laravel-entwickler' },
  { name: 'LaTeX', url: '/de/entwickler/latex-entwickler' },
  { name: 'LESS', url: '/de/entwickler/less-entwickler/' },
  { name: 'LevelDB', url: '/de/entwickler/leveldb-entwickler/' },
  { name: 'Lightning.js', url: '/de/entwickler' },
  { name: 'LINQ', url: '/de/entwickler/linq-entwickler/' },
  { name: 'Linux', url: '/de/entwickler' },
  { name: 'Lua', url: '/de/entwickler/lua-entwickler/' },
  { name: 'Maschinelles Lernen', url: '/de/entwickler' },
  { name: 'Magento', url: '/de/entwickler/magento-entwickler/' },
  { name: 'Maya', url: '/de/entwickler' },
  { name: 'MariaDB', url: '/de/entwickler/mariadb-entwickler' },
  { name: 'MATLAB', url: '/de/entwickler/matlab-entwickler' },
  { name: 'Maven', url: '/de/entwickler/maven-entwickler/' },
  { name: 'Memcached', url: '/de/entwickler/memcached-entwickler/' },
  { name: 'Meteor', url: '/de/entwickler/meteor-entwickler' },
  { name: 'Microsoft Access', url: '/de/entwickler' },
  { name: 'Microsoft SQL', url: '/de/entwickler' },
  { name: 'Mocha', url: '/de/entwickler/mocha-entwickler' },
  { name: 'MongoDB', url: '/de/entwickler/mongodb-entwickler' },
  { name: 'MPI', url: '/de/entwickler' },
  { name: 'Mustache.js', url: '/de/entwickler' },
  { name: 'MVC', url: '/de/entwickler/mvc-entwickler' },
  { name: 'MySQL', url: '/de/entwickler/mysql-entwickler' },
  { name: '.NET', url: '/de/entwickler' },
  { name: 'Neo4j', url: '/de/entwickler/neo4j-entwickler' },
  { name: 'Nginx', url: '/de/entwickler/nginx-entwickler' },
  { name: 'NHibernate', url: '/de/entwickler' },
  { name: 'NLTK', url: '/de/entwickler' },
  { name: 'Node.js', url: '/de/node-js-entwickler' },
  { name: 'NoSQL', url: '/de/entwickler/nosql-entwickler/' },
  { name: 'Objective-C', url: '/de/objective-c-entwickler' },
  { name: 'OOP', url: '/de/entwickler/oop-entwickler/' },
  { name: 'OpenCart', url: '/de/entwickler/opencart-entwickler/' },
  { name: 'OpenCV', url: '/de/entwickler' },
  { name: 'OpenGL', url: '/de/entwickler/opengl-entwickler' },
  { name: 'Open Source', url: '/de/entwickler' },
  { name: 'Oracle', url: '/de/entwickler/oracle-entwickler' },
  { name: 'Parse', url: '/de/entwickler' },
  { name: 'Pascal', url: '/de/entwickler/pascal-entwickler/' },
  { name: 'Perl', url: '/de/entwickler/perl-entwickler' },
  { name: 'Phoenix', url: '/de/entwickler' },
  { name: 'PhoneGap', url: '/de/entwickler/phonegap-entwickler' },
  { name: 'PHP', url: '/de/php-entwickler' },
  { name: 'PHPUnit', url: '/de/entwickler/phpunit-entwickler' },
  { name: 'PL/SQL', url: '/de/entwickler/pl/sql-entwickler' },
  { name: 'Polymer', url: '/de/entwickler/polymer-entwickler' },
  { name: 'PostgreSQL', url: '/de/entwickler/postgresql-entwickler' },
  { name: 'PrestaShop', url: '/de/entwickler/prestashop-entwickler/' },
  { name: 'Product Design', url: '/de/entwickler' },
  { name: 'Prototype.js', url: '/de/entwickler' },
  { name: 'Protractor', url: '/de/entwickler/protractor-entwickler/' },
  { name: 'PWA', url: '/de/entwickler' },
  { name: 'Python', url: '/de/python-entwickler' },
  { name: 'Qt', url: '/de/entwickler/qt-entwickler' },
  { name: 'R', url: '/de/entwickler/r-entwickler' },
  { name: 'RabbitMQ', url: '/de/entwickler/rabbitmq-entwickler' },
  { name: 'React', url: '/de/entwickler/react-entwickler' },
  { name: 'React.js', url: '/de/reactjs-entwickler' },
  { name: 'React Native', url: '/de/entwickler/react-native-entwickler/' },
  { name: 'Realm', url: '/de/entwickler/realm-entwickler/' },
  { name: 'Redis', url: '/de/entwickler/redis-entwickler/' },
  { name: 'Redux', url: '/de/redux-entwickler' },
  { name: 'REST', url: '/de/entwickler/rest-entwickler/' },
  { name: 'RESTful', url: '/de/entwickler/restful-entwickler' },
  { name: 'RethinkDB', url: '/de/entwickler/rethinkdb-entwickler' },
  { name: 'Retrofit', url: '/de/entwickler' },
  { name: 'RSpec', url: '/de/entwickler/rspec-entwickler' },
  { name: 'Ruby', url: '/de/entwickler/ruby-entwickler/' },
  { name: 'Ruby on Rails', url: '/de/ruby-on-rails-entwickler' },
  { name: 'Rust', url: '/de/entwickler/rust-entwickler' },
  { name: 'Sails.js', url: '/de/entwickler' },
  { name: 'SASS', url: '/de/entwickler/sass-entwickler/' },
  { name: 'Salesforce', url: '/de/entwickler/salesforce-entwickler' },
  { name: 'Salesforce API', url: '/de/entwickler' },
  { name: 'Scala', url: '/de/entwickler/scala-entwickler' },
  { name: 'Scrum', url: '/de/entwickler/scrum-entwickler' },
  { name: 'SCSS', url: '/de/entwickler/scss-entwickler' },
  { name: 'Shell Script', url: '/de/entwickler' },
  { name: 'Shopify', url: '/de/entwickler/shopify-entwickler/' },
  { name: 'Sinatra', url: '/de/entwickler/sinatra-entwickler' },
  { name: 'Sinon.js', url: '/de/entwickler' },
  { name: 'Sketch', url: '/de/entwickler' },
  { name: 'Smarty', url: '/de/entwickler' },
  { name: 'SOA', url: '/de/entwickler/soa-entwickler/' },
  { name: 'SOAP', url: '/de/entwickler/soap-entwickler/' },
  { name: 'Socket.IO', url: '/de/entwickler/socket-io-entwickler' },
  { name: 'Softwarearchitektur', url: '/de/entwickler' },
  { name: 'Software-Entwicklung', url: '/de/entwickler' },
  { name: 'Solr', url: '/de/entwickler/solr-entwickler/' },
  { name: 'Spark', url: '/de/entwickler/spark-entwickler' },
  { name: 'Spring', url: '/de/entwickler/spring-entwickler' },
  { name: 'Spring Boot', url: '/de/entwickler/spring-boot-entwickler' },
  { name: 'SQL', url: '/de/sql-entwickler' },
  { name: 'SQL-99', url: '/de/entwickler' },
  { name: 'SQLite', url: '/de/entwickler/sqlite-entwickler' },
  { name: 'Subversion', url: '/de/entwickler/subversion-entwickler/' },
  { name: 'Swift', url: '/de/swift-entwickler' },
  { name: 'Symfony', url: '/de/entwickler/symfony-entwickler' },
  { name: 'TDD', url: '/de/entwickler/tdd-entwickler' },
  { name: 'TensorFlow', url: '/de/entwickler/tensorflow-entwickler/' },
  { name: 'Three.js', url: '/de/entwickler' },
  { name: 'Titanium', url: '/de/entwickler' },
  { name: 'T-SQL', url: '/de/entwickler/t-sql-entwickler/' },
  { name: 'Trello', url: '/de/entwickler' },
  { name: 'TypeScript', url: '/de/entwickler/typescript-entwickler' },
  { name: 'Twig.js', url: '/de/entwickler' },
  { name: 'Twilio API', url: '/de/entwickler' },
  { name: 'TYPO3', url: '/de/entwickler/typo3-entwickler/' },
  {
    name: 'Ubuntu',
    url: 'http://codecontrol.io/de/entwickler/ubuntu-entwickler',
  },
  { name: 'UI', url: '/de/entwickler/ui-entwickler' },
  { name: 'UIKit', url: '/de/entwickler/uikit-entwickler' },
  { name: 'UML', url: '/de/entwickler/uml-entwickler/' },
  { name: 'Underscore.js', url: '/de/entwickler' },
  { name: 'Unix', url: '/de/entwickler/unix-entwickler' },
  { name: 'Unity', url: '/de/entwickler/unity-entwickler/' },
  { name: 'UX Design', url: '/de/entwickler' },
  { name: 'Vaadin', url: '/de/entwickler' },
  { name: 'Vagrant', url: '/de/entwickler/vagrant-entwickler/' },
  { name: 'VB.NET', url: '/de/entwickler' },
  { name: 'Visual Basic', url: '/de/entwickler/visual-basic-entwickler' },
  { name: 'Visual Studio', url: '/de/entwickler/visual-studio-entwickler/' },
  { name: 'Vue.js', url: '/de/vue-js-entwickler' },
  { name: 'WCF', url: '/de/entwickler/wcf-entwickler' },
  { name: 'Web-Entwicklung', url: '/de/web-entwickler' },
  { name: 'Web Services', url: '/de/entwickler/web-services-entwickler' },
  { name: 'Webpack', url: '/de/entwickler/webpack-entwickler' },
  { name: 'WinForms', url: '/de/entwickler' },
  { name: 'Wireframe', url: '/de/entwickler/wireframe-entwickler/' },
  { name: 'WooCommerce', url: '/de/woocommerce-entwickler' },
  { name: 'WordPress', url: '/de/wordpress-entwickler' },
  { name: 'WPF', url: '/de/entwickler/wpf-entwickler' },
  { name: 'Xamarin', url: '/de/entwickler/xamarin-entwickler' },
  { name: 'Xcode', url: '/de/entwickler/xcode-entwickler/' },
  { name: 'XHTML', url: '/de/entwickler/xhtml-entwickler/' },
  { name: 'XML', url: '/de/entwickler/xml-entwickler' },
  { name: 'XPath', url: '/de/entwickler' },
  { name: 'XSLT', url: '/de/entwickler/xslt-entwickler/' },
  { name: 'Yii', url: '/de/entwickler/yii-entwickler' },
  { name: 'YouTube API', url: '/de/entwickler' },
  { name: 'Zend', url: '/de/entwickler/zend-entwickler' },
  { name: 'ZURB', url: '/de/entwickler' },
]
