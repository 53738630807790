import * as React from 'react'
import _ from 'lodash'
import seedrandom from 'seedrandom'
import shuffle from 'shuffle-array'
import { RouteComponentProps } from '@reach/router'
import IndexLayout from '../../components/layout'
import Header from '../../components/Header'
import { injectIntl, InjectedIntlProps } from 'react-intl'
import Helmet from 'react-helmet'
import PageHeader from '../../components/PageHeader'
import styled, { ThemeProvider } from 'styled-components'
import {
  Teaser,
  CTA,
  media,
  themes,
  Container,
  Row,
  Col,
  gridLgSpacing,
  ImageBar,
  TwoColumn,
  TeaserImage,
  Spacer,
  FaqSection,
} from 'cc-ui-components'
import { bgColorMapping } from '../../styles/config'
import PageDefault from '../../components/Page'
import Timeline from '../../components/page/Timeline'
import { Title } from '../../components/page/Title'
import ContactForm from '../../components/page/ContactForm'
import { Grid } from '../../components/page/Grid'
// import * as SegmentAnalytics from '../../lib/segment'

interface DeveloperProps extends RouteComponentProps {
  pageContext: {
    language: string
    slug: string
    prefix: string
    skill: string
    jobTitlePlural: string
    jobTitleSingular: string
    pageTitle: string
  }
}

const Page = styled(PageDefault)`
  ${media.sm`
    & > div {
      & > div > div {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  `};
`

const SectionCol = styled(Col)`
  & > * {
    margin-top: 80px;
    &:first-child {
      margin-top: 0;
    }
  }
  ${media.sm`
   & > * {
      margin-top: 30px;
    }
    padding: 0 20px;
  `};
`

const developers = [
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/ZXyhen80hB0dctJjCmMFP/bb2657f1482375fa0f9109ed64de41e5/Dejan_Jurkovic__.jpg',
    name: 'Dejan Jurkovic',
    jobStringId: 'developers.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/36M0agGsWHNFiLwTJoVDFG/0d1e9f6306fd0bfc9da294677eeaa9f1/Harry_Parsons.jpg',
    name: 'Harry Parsons',
    jobStringId: 'developers.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/4R8wfDwcFF2hUgM1QCMuYm/337c0505febe24ac3d6930f5f8b1cb03/Jan_Beich.jpg',
    name: 'Jan Beich',
    jobStringId: 'developers.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/24dsyPvayLsm03saQwVYKP/6aea7e915339e392984a9fa5306b1d8a/Marjan_Novak.jpg',
    name: 'Marjan Novak',
    jobStringId: 'developers.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/6tlRutyYenLZeN9P5dGGAr/106100f9372fcbda67563ea287126f28/Milena_Slatinska__.jpg',
    name: 'Milena Slatinska',
    jobStringId: 'developers.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/aPDutzKmRSNHitCN30hrU/505863469ff4e04c2743cbeebdbce357/Nadir_Samaha.jpg',
    name: 'Nadir Samaha',
    jobStringId: 'developers.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/7FodMR3AyaTqvgRk2nLw1R/5d7b38253be30a259e9c77a2183d6626/Aatik_Patel.jpg',
    name: 'Aatik Patel',
    jobStringId: 'developers.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/61x1RCPdd1HgWozOFkVtZv/379b4b5c9543ce6f04539a95309b039d/Anastasia_Sorokina.jpg',
    name: 'Anastasia Sorokina',
    jobStringId: 'developers.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/5gzZ970cRe3IWrqW8MARWZ/8f06dbec41017533df608ec1ff8039c5/Benjamin_Agostini.jpg',
    name: 'Benjamin Agostini',
    jobStringId: 'developers.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/4dF1ARAS6LfDrhBIktS2wv/8577797c4a75731bfb51fe4e5f0f8b29/Daniel_Bernhard.jpg',
    name: 'Daniel Bernhard',
    jobStringId: 'developers.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/2cdL6LC9esvFDSwI7COV2/f7f539473ac5491ef50c3be116ae13ab/Dirk_Naumann.jpg',
    name: 'Dirk Naumann',
    jobStringId: 'developers.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/2YBX2zBEumjqyxtuPIeVlr/b5447f9fa8a34cd419c1cef87e32e91f/Lucas_Vuong.jpg',
    name: 'Lucas Vuong',
    jobStringId: 'developers.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/5bQ0tXJp0SNzfqdrwpOkjp/eea75bb2cf3c5408c584a1fd39539e50/Rita_Tello.jpg',
    name: 'Rita Tello',
    jobStringId: 'developers.grid.titleSenior',
  },
]

function getDevelopers(seed: string) {
  const rng = seedrandom(seed)
  return shuffle.pick(developers, { rng, picks: 6 })
}

class Developer extends React.Component<DeveloperProps & InjectedIntlProps> {
  /*componentDidMount(): void {
    const { intl, pageContext } = this.props
    const { prefix, skill, jobTitlePlural } = pageContext
    const title = intl.formatMessage(
      { id: 'developers.teaser1.title' },
      {
        prefix,
        skill,
        jobTitlePlural,
      }
    )
    requestAnimationFrame(() => {
      SegmentAnalytics.page(`Dynamic Webpage / A / ${title}`, {
        version: 'a1',
      })
    })
  }*/

  public render() {
    const { intl } = this.props
    const {
      language,
      slug,
      prefix,
      skill,
      jobTitlePlural,
      jobTitleSingular,
      pageTitle,
    } = this.props.pageContext

    const description = intl.formatMessage({
      id: 'developers.meta.description',
    })

    return (
      <React.Fragment>
        <Helmet htmlAttributes={{ lang: language }}>
          <title>CodeControl | {pageTitle} </title>
          <meta name="description" content={description} />
          <meta property="og:title" content={`CodeControl | ${pageTitle}`} />
          <meta property="og:description" content={description} />
          <meta property="og:locale" content={language} />
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <Header />
        <ThemeProvider theme={themes.light}>
          <PageHeader
            alignment="left"
            alignmentVertical="center"
            teaser={
              <Teaser
                title={intl.formatMessage(
                  { id: 'developers.teaser1.title' },
                  {
                    prefix,
                    skill,
                    jobTitlePlural,
                  }
                )}
                subtitle={intl.formatMessage({
                  id: 'developers.teaser1.subtitle',
                })}
                copy={{
                  childMarkdownRemark: {
                    html: `<p>${intl.formatMessage({
                      id: 'developers.teaser1.copy',
                    })}</p>`,
                  },
                }}
                links={
                  <CTA
                    title={intl.formatMessage({
                      id: 'developers.cta.getInTouch',
                    })}
                    internalLink={intl.formatMessage({
                      id: 'path.get-in-touch',
                    })}
                    type={'flat'}
                  />
                }
                linkSubtext={{
                  childMarkdownRemark: {
                    html: intl.formatMessage({
                      id: 'developers.teaser1.linkSubtext',
                    }),
                  },
                }}
              />
            }
            imageOverlayOpacity={0.75}
            imageOverlay="//images.ctfassets.net/6caxi523i64u/66Pk1JEj8h1zI59ynCDO7b/e806944426f3793b69c412933c2786b2/developer2.png?w=1170&q=70&fm=jpg"
          />
        </ThemeProvider>
        <Page>
          <ThemeProvider theme={themes.light}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.light}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Teaser
                    title={intl.formatMessage(
                      {
                        id: 'developers.teaser2.title',
                      },
                      {
                        skill,
                        jobTitlePlural,
                      }
                    )}
                    alignment="center"
                  />
                  <TwoColumn
                    elements={[
                      <Grid
                        key="grid-1"
                        gridElements={_.map(
                          getDevelopers(`${language}/${slug}`),
                          developer => ({
                            title: intl.formatMessage(
                              { id: developer.jobStringId },
                              {
                                prefix,
                                skill,
                                jobTitleSingular,
                              }
                            ),
                            profilePicture: developer.image,
                            fullName: developer.name,
                          })
                        )}
                      />,
                      <Timeline
                        key="col-1"
                        steps={[
                          {
                            title: intl.formatMessage(
                              {
                                id: 'developers.timeline1.step1.title',
                              },
                              {
                                skill,
                                jobTitlePlural,
                              }
                            ),
                            icon: null,
                            description: null,
                          },
                          {
                            title: intl.formatMessage({
                              id: 'developers.timeline1.step2.title',
                            }),
                            icon: null,
                            description: null,
                          },
                          {
                            title: intl.formatMessage({
                              id: 'developers.timeline1.step3.title',
                            }),
                            icon: null,
                            description: null,
                          },
                        ]}
                      />,
                    ]}
                    type="60/40"
                    reverse={true}
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.light}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.light}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Teaser
                    title={intl.formatMessage({
                      id: 'developers.teaser3.title',
                    })}
                    orientation="vertical"
                    copy={{
                      childMarkdownRemark: {
                        html: intl.formatMessage({
                          id: 'developers.teaser3.copy',
                        }),
                      },
                    }}
                  />
                  <ImageBar
                    images={[
                      '//images.ctfassets.net/6caxi523i64u/7chIWtMS6kuSwecUYW6ua4/a7a837e466d421c9d16b8f0eabc175c2/8gs.svg',
                      '//images.ctfassets.net/6caxi523i64u/58ZbjTZ1XyIwe4KWOCsu0S/410240c76541ef59225e13755a2e00f7/Dm_Logo__1_.svg',
                      '//images.ctfassets.net/6caxi523i64u/PT6rQjup2w4yMYe68s0QU/4387185b5fb469dbd690d2db436c9601/8gW.svg',
                      '//images.ctfassets.net/6caxi523i64u/9UpUdLS6xceGB26uMWKTz/f0372ba7cb0acf1cef35061d51f80135/mymuesli-logo.svg',
                      '//images.ctfassets.net/6caxi523i64u/5C0mlhRSCcQYIUoEICWiE/e98c000173338a3ba16aca15ee822af3/8hc.svg',
                      '//images.ctfassets.net/6caxi523i64u/4k0VJOHilyeeGykgEY2acw/6c41aea4330eb5c2c23b973251f6cf1e/8hi.svg',
                    ]}
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.regular}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.regular}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Teaser
                    title={intl.formatMessage(
                      {
                        id: 'developers.teaser4.title',
                      },
                      {
                        skill,
                        jobTitlePlural,
                      }
                    )}
                    subtitle={intl.formatMessage({
                      id: 'developers.teaser4.subtitle',
                    })}
                    alignment="center"
                  />
                  <TwoColumn
                    elements={[
                      <Teaser
                        key="teaser-1"
                        title={intl.formatMessage(
                          {
                            id: 'developers.teaser5.title',
                          },
                          {
                            skill,
                            jobTitlePlural,
                          }
                        )}
                        copy={{
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.teaser5.copy',
                            }),
                          },
                        }}
                        links={
                          <CTA
                            title={intl.formatMessage({
                              id: 'developers.cta.getInTouch',
                            })}
                            internalLink={intl.formatMessage({
                              id: 'path.get-in-touch',
                            })}
                            type={'flat'}
                          />
                        }
                      />,
                      <TeaserImage
                        key="teaser-image-1"
                        image="//images.ctfassets.net/6caxi523i64u/2ConpjPeIaoj8YsCnzeKw4/a15b28f7e996e39e6ac678e589f266ff/fatos-bytyqi-Agx5_TLsIf4-unsplash__1_.jpg?w=570&q=70&fm=jpg"
                      />,
                    ]}
                    type="50/50"
                  />
                  <Spacer height={50} />
                  <TwoColumn
                    elements={[
                      <TeaserImage
                        key="teaser-image-2"
                        image="//images.ctfassets.net/6caxi523i64u/33ku0Jx9pQPiyo9JPJGpGj/78ace01e97afc8386395b451636d2953/adi-goldstein-7BpeiA0bhxs-unsplash__1_.jpg?w=570&q=70&fm=jpg"
                        type="dark"
                      />,
                      <Teaser
                        key="teaser-2"
                        title={intl.formatMessage(
                          {
                            id: 'developers.teaser6.title',
                          },
                          {
                            skill,
                            jobTitlePlural,
                          }
                        )}
                        copy={{
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.teaser6.copy',
                            }),
                          },
                        }}
                        links={
                          <CTA
                            title={intl.formatMessage({
                              id: 'developers.cta.getInTouch',
                            })}
                            internalLink={intl.formatMessage({
                              id: 'path.get-in-touch',
                            })}
                            type={'flat'}
                          />
                        }
                      />,
                    ]}
                    type="50/50"
                  />
                  <Spacer height={50} />
                  <TwoColumn
                    elements={[
                      <Teaser
                        key="teaser-3"
                        title={intl.formatMessage(
                          {
                            id: 'developers.teaser7.title',
                          },
                          {
                            jobTitlePlural,
                          }
                        )}
                        copy={{
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.teaser7.copy',
                            }),
                          },
                        }}
                        links={
                          <CTA
                            title={intl.formatMessage({
                              id: 'developers.cta.getInTouch',
                            })}
                            internalLink={intl.formatMessage({
                              id: 'path.get-in-touch',
                            })}
                            type={'flat'}
                          />
                        }
                      />,
                      <TeaserImage
                        key="teaser-image-3"
                        image="//images.ctfassets.net/6caxi523i64u/1oqkHWKCFdWf2qEpPRuynp/7293bd37716ef3d28885756d10ae098f/amy-hirschi-JaoVGh5aJ3E-unsplash__1_.jpg?w=570&q=70&fm=jpg"
                      />,
                    ]}
                    type="50/50"
                  />
                  <Spacer height={50} />
                  <TwoColumn
                    elements={[
                      <TeaserImage
                        key="teaser-image-4"
                        image="//images.ctfassets.net/6caxi523i64u/eejQbJI0EB8MJQ3m8n1Je/bd1e57bfacb8e4091baa1e2cfabaa069/risks.jpg?w=570&q=70&fm=jpg"
                        type="dark"
                      />,
                      <Teaser
                        key="teaser-4"
                        title={intl.formatMessage(
                          {
                            id: 'developers.teaser8.title',
                          },
                          {
                            jobTitlePlural,
                          }
                        )}
                        copy={{
                          childMarkdownRemark: {
                            html: `<p>${intl.formatMessage({
                              id: 'developers.teaser8.copy',
                            })}</p>`,
                          },
                        }}
                        links={
                          <CTA
                            title={intl.formatMessage({
                              id: 'developers.cta.getInTouch',
                            })}
                            internalLink={intl.formatMessage({
                              id: 'path.get-in-touch',
                            })}
                            type={'flat'}
                          />
                        }
                      />,
                    ]}
                    type="50/50"
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.regular}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.regular}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Teaser
                    title={intl.formatMessage({
                      id: 'developers.teaser9.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'developers.teaser9.subtitle',
                    })}
                    alignment="center"
                  />
                  <Timeline
                    title="Steps"
                    showStepNumbers={true}
                    steps={[
                      {
                        title: intl.formatMessage({
                          id: 'developers.timeline2.step1.title',
                        }),
                        icon: {
                          file: {
                            url:
                              '//images.ctfassets.net/6caxi523i64u/2mDK9JzR5mOaeUAKgCEM4C/548ef4b0e4f64c51f664d1113d150d84/Conversation.svg',
                          },
                        },
                        description: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.timeline2.step1.description',
                            }),
                          },
                        },
                      },
                      {
                        title: intl.formatMessage({
                          id: 'developers.timeline2.step2.title',
                        }),
                        icon: {
                          file: {
                            url:
                              '//images.ctfassets.net/6caxi523i64u/3Ax5RsCis0W4I0AwC0yqMu/aa9f14de81ddc213524b82daa53912f9/Talent.svg',
                          },
                        },
                        description: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.timeline2.step2.description',
                            }),
                          },
                        },
                      },
                      {
                        title: intl.formatMessage({
                          id: 'developers.timeline2.step3.title',
                        }),
                        icon: {
                          file: {
                            url:
                              '//images.ctfassets.net/6caxi523i64u/5IPNjFl3jOMEIGsCuISsMo/afb6f701bc2d83d5ab89824a6b6dfbd7/Devices.svg',
                          },
                        },
                        description: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.timeline2.step3.description',
                            }),
                          },
                        },
                      },
                      {
                        title: intl.formatMessage({
                          id: 'developers.timeline2.step4.title',
                        }),
                        icon: {
                          file: {
                            url:
                              '//images.ctfassets.net/6caxi523i64u/63lf4oIle8aMEOSKcGUCam/aabf8f8b75d4e751ae19c631be3e53cc/Checklist.svg',
                          },
                        },
                        description: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.timeline2.step4.description',
                            }),
                          },
                        },
                      },
                    ]}
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.regular}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.regular}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Title
                    title={intl.formatMessage({
                      id: 'developers.faq.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'developers.faq.subtitle',
                    })}
                    alignment="center"
                  />
                  <FaqSection
                    elements={[
                      {
                        question: intl.formatMessage({
                          id: 'developers.faq.q1.title',
                        }),
                        answer: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.faq.q1.answer',
                            }),
                          },
                        },
                      },
                      {
                        question: intl.formatMessage({
                          id: 'developers.faq.q2.title',
                        }),
                        answer: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.faq.q2.answer',
                            }),
                          },
                        },
                      },
                      {
                        question: intl.formatMessage({
                          id: 'developers.faq.q3.title',
                        }),
                        answer: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.faq.q3.answer',
                            }),
                          },
                        },
                      },
                      {
                        question: intl.formatMessage({
                          id: 'developers.faq.q4.title',
                        }),
                        answer: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'developers.faq.q4.answer',
                            }),
                          },
                        },
                      },
                    ]}
                  />
                  <Teaser
                    title={intl.formatMessage({
                      id: 'developers.teaser10.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'developers.teaser10.subtitle',
                    })}
                    alignment="center"
                    links={
                      <CTA
                        title={intl.formatMessage({
                          id: 'developers.cta.getAQuote',
                        })}
                        internalLink={intl.formatMessage({
                          id: 'path.get-in-touch',
                        })}
                        type={'flat'}
                      />
                    }
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.dark}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.dark}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <ContactForm
                    title={intl.formatMessage({
                      id: 'developers.contact.title',
                    })}
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>
        </Page>
      </React.Fragment>
    )
  }
}

const DeveloperIntl = injectIntl(Developer)

const DeveloperWrapper = (props: DeveloperProps) => (
  <IndexLayout location={props.location} hideFooter={true}>
    <DeveloperIntl {...props} />
  </IndexLayout>
)

export default DeveloperWrapper
