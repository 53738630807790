import * as React from 'react'
import { TabbedTeaser as DefaultTabbedTeaser } from 'cc-ui-components'
import { convertContentfulImage } from '../DynamicAssembly'

export const TabbedTeaser: React.SFC<any> = ({ tabElements, ...rest }) => {
  if (!tabElements || tabElements.lenght) {
    return null
  }
  const newTabElements = tabElements.map((element: any) => ({
    ...element,
    tabIcon: convertContentfulImage(element.tabIcon),
  }))
  return <DefaultTabbedTeaser {...rest} tabElements={newTabElements} />
}
