import * as React from 'react'

import { IconProps } from '../DynamicAssembly'
import styled, { css } from 'styled-components'
import PageHeader from '../PageHeader'
import { media } from 'cc-ui-components'

interface Props {
  image?: IconProps
  pageHeader?: any
  children?: any
}

interface WrapperProps {
  isHeader: boolean
}

const Wrapper = styled.div`
  width: 100vw;
  position: relative;
  background-color: transparent;
  header {
    padding: 30px 0;
    background-color: transparent;
    * {
      background-color: transparent;
    }
  }
  ${(props: WrapperProps) =>
    props.isHeader &&
    css`
      padding-top: 119px;
      img {
        position: absolute;
        object-fit: cover;
        height: 100%;
      }
      header > div:first-of-type {
        top: 0;
        height: 100%;
      }
      ${media.sm`
        padding-top: 0;
      `};
    `};
`

const Image = styled.img`
  width: 100vw;
  max-height: 677px;
  ${media.sm`
    max-height: unset;
  `};
`

const FullwidthImage: React.SFC<Props> = ({ image, pageHeader, children }) => {
  if (!image || !image.file) {
    return null
  }
  return (
    <Wrapper isHeader={pageHeader || children}>
      <Image src={image.file.url} />
      {pageHeader && <PageHeader {...pageHeader} />}
      {children || null}
    </Wrapper>
  )
}

export default FullwidthImage
