import * as React from 'react'
import { Grid as DefaultGrid } from 'cc-ui-components'
import { convertContentfulImage } from '../DynamicAssembly'

export const Grid: React.SFC<any> = ({ gridElements, ...rest }) => {
  if (!gridElements || !gridElements.length) {
    return null
  }
  const elements = gridElements.map((element: any) => {
    if (element.__typename === 'ContentfulContentUsp') {
      return {
        ...element,
        icon: convertContentfulImage(element.icon),
      }
    }
    return {
      ...element,
      profilePicture: convertContentfulImage(element.profilePicture),
      image: convertContentfulImage(element.image),
    }
  })
  return <DefaultGrid {...rest} gridElements={elements} />
}
